html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fffff0;
}

* {
  font-family: 'Montserrat', sans-serif;
}
